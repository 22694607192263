import React, { useRef, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton } from '@mui/material'
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'

import { ImageContainer } from './styles'

type ViewImageDialogPropsType = {
  item?: File;
  visible: boolean;
  onClose: () => void;
}

const ViewImageDialog = ({ item, visible, onClose }: ViewImageDialogPropsType) => {
  const imageRef = useRef()
  const containerRef = useRef()
  const [crop, setCrop] = useState({ x: 0, y: 0, scale: 1 })

  const useGesture = createUseGesture([dragAction, pinchAction])

  const bind = useGesture(
    {
      onDrag: ({ pinching, cancel, movement: [ax, ay], xy, initial, delta, offset, distance }) => {
        if (pinching) {
          cancel()
          return
        }

        if (delta[0] || delta[1]) {
          setCrop((oldCrop) => ({ ...oldCrop, x: oldCrop.x + delta[0], y: oldCrop.y + delta[1] }))
        } else {
          setCrop((oldCrop) => ({ ...oldCrop, x: ax, y: ay }))
        }
      },
      onPinch: ({ offset: [d] }) => {
        setCrop((oldCrop) => ({ ...oldCrop, scale: d }))
      },
      onDragEnd: () => {
        const newCrop = crop
        // @ts-ignore
        const imageBounds = imageRef.current.getBoundingClientRect()
        // @ts-ignore
        const containerBounds = containerRef?.current?.getBoundingClientRect()
        // @ts-ignore
        const originalWidth = imageRef.current.clientWidth
        // @ts-ignore
        const originalHeight = imageRef.current.clientHeight

        const widthOverhang = (imageBounds.width - originalWidth) / 2
        const heightOverhang = (imageBounds.height - originalHeight) / 2

        if (imageBounds.left > containerBounds.left) {
          newCrop.x = widthOverhang
        } else if (imageBounds.right < containerBounds.right) {
          newCrop.x = -(imageBounds.width - containerBounds.width) + widthOverhang
        }

        if (imageBounds.top > containerBounds.top) {
          newCrop.y = heightOverhang
        } else if (imageBounds.bottom < containerBounds.bottom) {
          newCrop.y =
            -(imageBounds.height - containerBounds.height) + heightOverhang
        }
        setCrop((oldCrop) => ({ ...oldCrop, ...newCrop }))
      },
      onPinchEnd: () => {
        const newCrop = crop
        // @ts-ignore
        const imageBounds = imageRef.current.getBoundingClientRect()
        // @ts-ignore
        const containerBounds = containerRef?.current?.getBoundingClientRect()
        // @ts-ignore
        const originalWidth = imageRef.current.clientWidth
        // @ts-ignore
        const originalHeight = imageRef.current.clientHeight

        const widthOverhang = (imageBounds.width - originalWidth) / 2
        const heightOverhang = (imageBounds.height - originalHeight) / 2

        if (imageBounds.left > containerBounds.left) {
          newCrop.x = widthOverhang
        } else if (imageBounds.right < containerBounds.right) {
          newCrop.x = -(imageBounds.width - containerBounds.width) + widthOverhang
        }

        if (imageBounds.top > containerBounds.top) {
          newCrop.y = heightOverhang
        } else if (imageBounds.bottom < containerBounds.bottom) {
          newCrop.y =
            -(imageBounds.height - containerBounds.height) + heightOverhang
        }
        setCrop((oldCrop) => ({ ...oldCrop, ...newCrop }))
      }
    }, {
      drag: { filterTaps: true, rubberband: true },
      pinch: { scaleBounds: { min: 1 }, filterTaps: true }
    }
  )

  const handleClose = () => {
    setCrop({ x: 0, y: 0, scale: 1 })
    onClose()
  }

  const renderFile = (file: File) => {
    return URL.createObjectURL(file)
  }

  const renderContent = () => {
    if (!item) {
      return null
    }

    return (
      <img {...bind()} style={{
        position: 'relative',
        left: crop.x,
        top: crop.y,
        touchAction: 'none',
        transform: `scale(${crop.scale})`
      }}
      ref={imageRef as unknown as React.LegacyRef<HTMLImageElement> | undefined}
      src={renderFile(item)} />
    )
  }

  return (
    <Dialog open={visible}
      onClose={handleClose}
    >
      <IconButton
        onClick={handleClose}
        sx={() => ({
          position: 'absolute',
          right: 4,
          top: 4,
          color: 'white',
          zIndex: 999
        })}
      >
        <CloseIcon />
      </IconButton>
      <ImageContainer
        ref={containerRef as unknown as ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined}
      >
        {renderContent()}
      </ImageContainer>
    </Dialog>
  )
}

export default ViewImageDialog
