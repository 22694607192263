import React, { memo, useMemo } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material'

type ImagePreviewPropsType = {
  item: File;
  size?: number;
  onRemove?: (item: File) => void;
  onClick: (item: File) => void;
}

const ImagePreview = ({ item, onClick, size = 90, onRemove }: ImagePreviewPropsType) => {
  const fileSource = useMemo(() => {
    return URL.createObjectURL(item)
  }, [item])

  const renderExcludeOption = () => {
    if (!onRemove) {
      return null
    }

    return (
      <ImageListItemBar
        sx={{
          background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
        }}
        position="top"
        actionIcon={
          <IconButton
            sx={{ color: 'white' }}
            onClick={() => onRemove(item)}
            aria-label={`star ${item.name}`}
          >
            <DeleteIcon />
          </IconButton>
        }
        actionPosition="right"
      />
    )
  }

  return (
    <ImageListItem style={{ width: size, height: size, margin: 4 }}>
      <img src={fileSource} onClick={() => onClick(item)}/>
      {renderExcludeOption()}
    </ImageListItem>
  )
}

export default memo(ImagePreview)
