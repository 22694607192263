import React, { ChangeEventHandler, useCallback, useRef, useState } from 'react'
import ReactQuill from 'react-quill'

import { AddPhotoAlternate } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'

import ImagePreview from './components/ImagePreview'
import ViewImageDialog from './components/ViewImageDialog'
import * as S from './styles'
import { CreatePostProps } from './types'

const CreatePost: React.FC<CreatePostProps> = ({
  html,
  isHtmlEmpty,
  modules,
  handleChangeHtml,
  isLoading,
  isLoadingCreatePost,
  createPost,
  handlerUploadImage,
  t
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [selectedImages, setSelectedImages] = useState<File[]|[]>([])
  const [visible, setVisible] = useState(false)
  const [itemToView, setItemToView] = useState<File|undefined>(undefined)

  const handleClickAddImage = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  const handleSelectedImages: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const fileList = event.target.files

    if (!fileList?.length) {
      return []
    }

    const files = Array.from(fileList)
    setSelectedImages(files)
  }, [])

  const handleClose = () => {
    setVisible(false)
    setItemToView(undefined)
  }

  const handleRemove = useCallback((image: File) => {
    setSelectedImages((prevImages) => {
      const tempImages = [...prevImages]

      const index = tempImages.findIndex(item => item.name === image.name && item.type === image.type)

      if (index > -1) {
        tempImages.splice(index, 1)

        if (tempImages.length === 0) {
          inputFileRef.current!.value = ''
        }
      }

      return tempImages
    })
  }, [])

  const handleOpenView = useCallback((item: File) => {
    setVisible(true)
    setItemToView(item)
  }, [])

  const handleClickCreate = () => {
    if (!selectedImages.length) {
      createPost([])
    } else {
      handlerUploadImage(selectedImages)
      setSelectedImages([])
      inputFileRef.current!.value = ''
    }
  }

  const renderImages = () => {
    return selectedImages.map(item => (
      <ImagePreview
        key={item.name}
        item={item}
        onClick={handleOpenView}
        onRemove={handleRemove}/>
    ))
  }

  if (isLoading) {
    return <></>
  }

  return (
    <S.ContainerCard style={{ paddingTop: 8, width: '100%' }}>
      <ReactQuill
        theme="snow"
        value={html}
        onChange={handleChangeHtml}
        modules={modules}
        placeholder={t('CREATED_POST.POST_A_NOTICE_YOUR_MEMBERS')}
      />
      <ViewImageDialog visible={visible} item={itemToView} onClose={handleClose}/>
      <S.ContainerButton>
        <S.InputSelectionContainer>
          <input id="avatar" name="avatar" type="file" style={{ opacity: 0, overflow: 'hidden', position: 'absolute', top: 120 }}
            ref={inputFileRef} accept="image/png, image/jpeg" onChange={handleSelectedImages}/>
        </S.InputSelectionContainer>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleClickAddImage}
        >
          {isLoadingCreatePost
            ? <CircularProgress size={24} color='primary'/>
            : (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                <AddPhotoAlternate fontSize="small"/>{t('CREATED_POST.ADD_IMAGE')}
              </div>)}
        </Button>
      </S.ContainerButton>
      <S.SelectedImageContainer>
        {selectedImages.length > 0 ? renderImages() : null}
      </S.SelectedImageContainer>
      <S.ContainerButton>
        <Button variant="contained" color="primary" onClick={handleClickCreate} fullWidth disabled={isHtmlEmpty && !selectedImages.length}>
          {isLoadingCreatePost ? <CircularProgress size={24} color='info'/> : t('CREATED_POST.PUBLISH')}
        </Button>
      </S.ContainerButton>
    </S.ContainerCard>
  )
}

export default CreatePost
