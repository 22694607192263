import imageCompression from 'browser-image-compression'

import { api } from '../../helpers/axios'
import { IUploadFileResponse } from './types'

async function uploadFile (images: File[]): Promise<IUploadFileResponse> {
  const formData = new FormData()
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    alwaysKeepResolution: true
  }

  const compressedPromises:Promise<File>[] = []
  images.map(async item => {
    compressedPromises.push(imageCompression(item, options))
  })

  const compressedFiles = await Promise.all(compressedPromises)
  compressedFiles.map(item => {
    const name = item.name.split('.')
    return formData.append('files', item!, name[0])
  })

  const response = await api.post<IUploadFileResponse>('/oci-attachments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export const uploadService = {
  uploadFile
}
