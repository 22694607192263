import { api } from '../../helpers/axios'
import { IBasePageParams, IBasePageReactionParams, IBasePageResponse } from '../types'
import {
  AttachmentsType,
  IBasePageCommentAnswerParams,
  IBasePageCommentParams, IComment, ICommentAnswer, ICreateComment, ICreateCommentAnswer, ICreatePost, ICreateReaction,
  ICreateVisualization, IDeletePost, IPost, IReactionResponse, IViewUserResponse
} from './types'

async function getPosts (params: IBasePageParams): Promise<IBasePageResponse<IPost[]>> {
  const response = await api.get<IBasePageResponse<IPost[]>>('posts', { params })
  return response.data
}

async function createPost (body: ICreatePost): Promise<string> {
  const response = await api.post<string>('posts', body)
  return response.data
}

async function reactToPost (params: ICreateReaction): Promise<IReactionResponse> {
  const response = await api.post<IReactionResponse>(`posts/${params.post_id}/reactions`, { reaction: params.reaction })
  return response.data
}

async function viewUserPost (params: ICreateVisualization): Promise<IViewUserResponse> {
  const response = await api.post<IViewUserResponse>(`posts/${params.postId}/views`)
  return response.data
}

async function deletePost (params: IDeletePost): Promise<string> {
  const response = await api.delete<string>(`posts/${params.post_id}`)
  return response.data
}

async function getAuthorAvatar (authorEmail: string): Promise<any> {
  const response = await api.get<any>('/users/profile/photo', {
    responseType: 'blob',
    headers: { disabledToast: true },
    params: {
      email: authorEmail
    }
  })
  return response.data
}

async function getPostById (postId: string): Promise<IPost> {
  const response = await api.get<IPost>(`/posts/${postId}`)
  return response.data
}

async function getComments (params :IBasePageCommentParams): Promise<IBasePageResponse<IComment[]>> {
  const response = await api.get<IBasePageResponse<IComment[]>>(`/post-comments/posts/${params.postId}`, {
    params: {
      page: params.page,
      limit: params.limit
    }
  })
  return response.data
}

async function getCommentsAnswers (params :IBasePageCommentAnswerParams): Promise<IBasePageResponse<ICommentAnswer[]>> {
  const response = await api.get<IBasePageResponse<ICommentAnswer[]>>(`post-comments-answers/comments/${params.commentId}`, {
    params: {
      page: params.page,
      limit: params.limit
    }
  })
  return response.data
}
async function getReactions (params: IBasePageReactionParams): Promise<IBasePageResponse<IReactionResponse[]>> {
  const response = await api.get<IBasePageResponse<IReactionResponse[]>>(`posts/${params.postId}/reactions`, {
    params: {
      page: params.page,
      limit: params.limit
    }
  })
  return response.data
}

async function createComment (params: ICreateComment): Promise<IComment> {
  const response = await api.post<IComment>(`post-comments/posts/${params.postId}`, {
    comment: params.comment
  }, {
    headers: { disabledToast: true }
  })
  return response.data
}

async function createCommentAnswer (params: ICreateCommentAnswer): Promise<ICommentAnswer> {
  const response = await api.post<ICommentAnswer>(`post-comments-answers/comments/${params.commentId}`, {
    comment_answer: params.comment_answer
  }, {
    headers: { disabledToast: true }
  })
  return response.data
}

async function fetchAttachments (attachment: AttachmentsType[]): Promise<File[]> {
  const urls = attachment?.map(item => {
    return api.get(`oci-attachments/${item.file_key}`, {
      responseType: 'blob'
    })
  })
  const responses = await Promise.all(urls)
  return responses.map(response => response.data)
}

export const postService = {
  getPosts,
  createPost,
  reactToPost,
  viewUserPost,
  deletePost,
  getAuthorAvatar,
  getPostById,
  getComments,
  getReactions,
  createComment,
  getCommentsAnswers,
  createCommentAnswer,
  fetchAttachments
}
