import React, { useCallback, useEffect, useState } from 'react'

import { BrokenImage } from '@mui/icons-material'
import { CardContent, Link } from '@mui/material'

import ImagePreview from '../../../pages/Home/components/Posts/CreatePost/components/ImagePreview'
import ViewImageDialog from '../../../pages/Home/components/Posts/CreatePost/components/ViewImageDialog'
import { postService } from '../../../services/PostService'
import { AttachmentsType } from '../../../services/PostService/types'
import { truncatedContent } from '../../../utils/global'

const CHARACTER_LENGTH = 811

type Props = {
  content: string;
  attachment?: AttachmentsType[]
};

const PostContent: React.FC<Props> = ({ content, attachment }) => {
  const [isExpandedContent, setIsExpandedContent] = useState(false)
  const [images, setImages] = useState<File[]>([])
  const [visible, setVisible] = useState(false)
  const [itemToView, setItemToView] = useState<File|undefined>(undefined)
  const [showErrorImage, setShowErrorImage] = useState(false)
  const [showImageComponent, setShowImageComponent] = useState(true)

  const handleClickExpand = () => {
    setIsExpandedContent(oldState => !oldState)
  }

  const handleClose = () => {
    setVisible(false)
    setItemToView(undefined)
  }

  const handleOpenView = (item: File) => {
    setVisible(true)
    setItemToView(item)
  }

  const fetchAttachment = useCallback(async () => {
    try {
      setShowErrorImage(false)
      const attachmentFiles = await postService.fetchAttachments(attachment!)

      if (attachmentFiles.length) {
        setImages(attachmentFiles)
      }
    } catch (error: any) {
      setShowErrorImage(true)
    }
  }, [])

  const renderImages = () => {
    if (!attachment) {
      return null
    }

    if (showErrorImage) {
      return (
        <BrokenImage fontSize='large'/>
      )
    }

    return images.map(item => {
      return (
        <ImagePreview
          key={item.name}
          size={180}
          item={item}
          onClick={() => handleOpenView(item)}
        />
      )
    })
  }

  const cleanContent = () => {
    if (content.includes('<img')) {
      setShowImageComponent(false)
    }
  }

  useEffect(() => {
    cleanContent()
    if (attachment?.length) {
      fetchAttachment()
    }
  }, [])

  return (
    <CardContent style={{ padding: 0 }}>
      <div className="ql-editor">
        <div dangerouslySetInnerHTML={{ __html: isExpandedContent ? content : truncatedContent(content, CHARACTER_LENGTH) }} />
        {content.length > CHARACTER_LENGTH && (
          <Link
            component="button"
            variant="body2"
            onClick={handleClickExpand}
          >
            {isExpandedContent ? 'Ver menos' : 'Ver mais'}
          </Link>
        )}
        <ViewImageDialog visible={visible} item={itemToView} onClose={handleClose}/>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center' }}>
          {showImageComponent ? renderImages() : null}
        </div>
      </div>
    </CardContent>
  )
}

export default PostContent
