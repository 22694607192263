import { Grid } from '@mui/material'
import styled from 'styled-components'

export const ContainerCard = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 6,
  sm: 8
})`
  
  .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .ql-toolbar.ql-snow {
    display: block;
    background: #f5f5f5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

  }
  
  .ql-tooltip.ql-editing {
    z-index: 999;
  }

  @media screen and (min-width: 330px) and (max-width: 399px) {
    .ql-picker-options {
      transform: translateX(-57%);
    }
  }
`

export const ContainerButton = styled(Grid)`
  margin-top: 16px;
  margin-bottom: 24px;
`
export const InputSelectionContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;
`
export const SelectedImageContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap
`
